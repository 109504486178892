import { on } from 'delegated-events'

const { KlaviyoSubscribe } = window

let initialCan = document.querySelector('.can-item.is-init svg')

const brandColors = [
  '#ffc600',
  '#ff8672',
  // '#dcc7b7',
  '#006098',
  '#752e4a',
  '#ddb5c8',
  '#ffd720',
  '#93d500',
  '#f4436c',
  '#ff8300',
  '#4a9462',
  '#db0032',
]

const fn = {
  init: () => {
    fn.setupCans()

    if (initialCan) {
      fn.handleMouseOver(initialCan)
    }

    fn.bindEvents()
    fn.setupEmail()
  },

  setupCans: () => {
    fn.shuffleArray(brandColors)

    document.querySelectorAll('.can-item svg').forEach((el, index) => {
      el.dataset.color = brandColors[index]
    })
  },

  bindEvents: () => {
    on('mouseover', '.can-item svg', (e) => {
      if (initialCan) {
        fn.handleMouseOut(initialCan)
        initialCan = null
      }

      fn.handleMouseOver(e.currentTarget)
    })

    on('mouseout', '.can-item svg', (e) => {
      fn.handleMouseOut(e.currentTarget)
    })
  },

  handleMouseOver: (svg) => {
    const color = svg.dataset.color

    svg.style.setProperty('--can-fill', color)
    svg.style.setProperty('--can-stroke', color)
    svg.style.setProperty('--text-fill', '#fff')
  },

  handleMouseOut: (svg) => {
    svg.style.setProperty('--can-fill', 'none')
    svg.style.setProperty('--can-stroke', '#000')
    svg.style.setProperty('--text-fill', '#000')
  },

  setupEmail: () => {
    KlaviyoSubscribe.attachToForms('.email-signup', {
      success: (resp) => {
        const $form = resp[0]

        if ($form) {
          const $signUpSuccess = $form.parentNode.querySelector(
            '.sign-up-success'
          )

          $form.style.display = 'none'

          if ($signUpSuccess) {
            $signUpSuccess.style.display = 'block'
          }
        }

        window.dataLayer &&
          window.dataLayer.push({
            event: 'emailListSubscribed',
            props: {
              listName: 'Footer',
            },
          })
      },
    })
  },

  shuffleArray: (array) => {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1))
      var temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
  },
}

fn.init()
